@font-face {
  font-family: "SukhumvitSet-Medium";
  src: url(assets/fonts/SukhumvitSet-Medium.ttf) format("opentype");
}

@font-face {
  font-family: "SukhumvitSet-SemiBold";
  src: url(assets/fonts/SukhumvitSet-SemiBold.ttf) format("opentype");
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #b31117; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b31117; 
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
}

.main {
  height: 100%;
  width: calc(100% - 65px);
  background-color: #EEEEEE;
}

.main-container {
  height: calc(100% - 65px);
  width: 100%;
  display: flex;
  flex-direction: row;
}

.open-sidebar-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 60px;
  width: 30px;
  background-color: #b31117;
  right: -30px;
  z-index: 1;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.open-sidebar-btn > img {
  height: 20px;
  width: auto;
  transition: 0.5s;
}

/* ---- Left Nav ---- */
.left-nav-container {
  height: 100%;
  width: 65px;
  border-right: 2px solid rgba(0, 0, 0, 0.1);
}

.nav-logo-container, .nav-footer {
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-logo-container {
  margin-bottom: 30px;
}

.logo-icon {
  height: 60%;
  width: auto;
  cursor: pointer;
}

.nav-menu {
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 30px;
}

.nav-menu-icon {
  height: 70%;
  width: auto;
  cursor: pointer;
}

.nav-menu.active > .nav-menu-icon {
  filter: invert(10%) sepia(88%) saturate(5567%) hue-rotate(353deg) brightness(91%) contrast(94%);
}

.nav-menu.active::after {
  content: "";
  position: absolute;
  border-right: 2px solid #b31117;
  height: 100%;
  right: -1px;
}

.nav-menu-container {
  height: calc(100% - 140px);
  width: 100%;
}

/* ---- Top Nav ---- */
.top-nav-container {
  height: 55px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.nav-navigation-container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 40px;
}

.nav-navigation-container > div {
  margin: 0 30px;
  padding: 0 10px;
  font-family: "SukhumvitSet-SemiBold";
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  
}

.nav-navigation-container > div.active {
  color: #b31117;
  position: relative;
}

.nav-navigation-container > div.active::after {
  content: "";
  border-bottom: 2px solid #b31117;
  position: absolute;
  width: 100%;
  bottom: -2px;
  left: 0;
}

/* ----- Tracking ----- */
.body {
  width: calc(100% - 40px);
  height: calc(100% - 95px);
  margin: 20px;
  display: flex;
}

.left-container {
  height: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.5s;
  border-radius: 4px;
}

.right-container {
  height: 100%;
  width: calc(100% - 300px);
  transition: 0.5s;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.filter-container {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px;
}

.filter-container > p {
  font-family: "SukhumvitSet-Medium";
  font-size: 14px;
  margin-left: 15px;
  margin-bottom: 0;
  color: #888888;
}

.filter-container > .toggle-btn {
  background-color:#F6F7FB;
  height: 38px;
  width: 38px;
  border: 2px solid #F6F7FB;
  color: #888888;
  cursor: pointer;
  font-family: "SukhumvitSet-SemiBold";
  font-size: 14px;
  margin-left: 10px;
  border-radius: 4px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
} 

.toggle-btn > img {
  height: 50%;
  width: auto;
  transition: 0.5s;
}

.map-container {
  height: calc(100% - 230px);
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.wrapper, .wrapper-job {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper-job {
  border: 3px solid blue;
  background-color: #ffffff;
}

.marker-logo {
  height: 45px;
  width: auto;
}

.marker-title-container {
  height: 85px;
  width: 85px;
  background-color: #ffffff;
  border: 3px solid #b31117;
  border-radius: 100%;
  position: absolute;
  top: -125px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.marker-title-container::before, .marker-jobs-title-container::before {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: -40px;
  left: calc(50% - 10px);
  border: 10px solid transparent;
  border-top-width: 30px;
  border-top-color: #b31117;
}

.marker-jobs-title-container::before {
  bottom: -40px;
  border-top-color: blue;
}

.marker-image-container {
  height: 79px;
  width: 79px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marker-image {
  height: 79px;
  width: 79px;
  object-fit: cover;
  border-radius: 100%;
}

.info-container {
  position: absolute;
  height: 80px;
  width: 280px;
  background: #ffffff;
  right: -250px;
  border: 3px solid #b31117;
  border-radius: 6px;
  border-left-color: transparent;
  z-index: -1;
  color: black;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.info-text {
  margin-left: 40px;
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "SukhumvitSet-Medium";
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-container {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.search-container > div {
  width: 70%;
}

.technician-list-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.technician-info-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 7.5px 0;
  margin-bottom: 10px;
  margin-right: 10px;
  background-color: #ffffff;
  border-radius: 4px;
}

.technician-info-card:last-child {
  margin-bottom: 0;
}

.technician-info-card-image {
  height: 55px;
  width: 55px;
  object-fit: cover;
  margin: 5px 10px;
  border-radius: 100%;
  border: 2.5px solid;
}

.technician-info-card-title-container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 65px;
}

.technician-info-card-title-container > p {
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "SukhumvitSet-Medium";
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}

.marker-jobs-title-container {
  height: 100px;
  width: 250px;
  padding: 5px 10px;
  background-color: #ffffff;
  border: 3px solid blue;
  position: absolute;
  border-radius: 6px;
  top: -150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: none;
  color: #000;
}

.clear-btn, .search-btn {
  background-color:#F6F7FB;
  height: 38px;
  border: 2px solid #F6F7FB;
  color: #888888;
  cursor: pointer;
  font-family: "SukhumvitSet-SemiBold";
  font-size: 14px;
  margin-left: 10px;
  border-radius: 4px;
  padding: 0 10px;
}

.job-list-title {
  font-family: "SukhumvitSet-SemiBold";
  margin-top: 10px;
  margin-bottom: 0px;
}

.job-info-container {
  margin-top: 10px;
  height: 150px;
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
}

.job-info {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: calc(50% - 10px);
  height: 100%;
}

.job-info:nth-last-child(-n + 2) {
  margin-bottom: 0px;
}

.job-info-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.job-title, .job-info-title {
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "SukhumvitSet-Medium";
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-info, .job-title {
  white-space: nowrap;
}

.modal-title {
  font-family: "SukhumvitSet-Medium";
  text-align: center;
}

/* ---- History ---- */
.history-map-container {
  height: calc(100% - 70px);
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.timeline-container {
  margin-left: 10px;
  overflow-y: auto;
  border-radius: 4px;
  background-color: #ffffff;
  font-family: "SukhumvitSet-Medium";
  padding: 10px;
  margin-right: 10px;
}

.timeline-container > h5 {
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  font-family: "SukhumvitSet-SemiBold";
}

.timeline-container > p {
  margin-left: 5px;
  margin-bottom: 15px;
  font-family: "SukhumvitSet-Medium";
}

.timeline-info {
  margin-left: 10px;
  padding-left: 15px;
  font-size: 14px;
  padding-bottom: 30px;
  position: relative;
}

.timeline-info:last-child {
  padding-bottom: 0;
}

.timeline-info::after {
  content: "";
  height: 100%;
  width: 2px;
  background-color: #b31117;
  position: absolute;
  top: 5%;
  left: -5px;
}

.timeline-info:last-child::after {
  background-color: transparent;
}

.timeline-info::before {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: #b31117;
  border-radius: 100%;
  top: 5%;
  left: -9px;
}

.timeline-location {
  margin-bottom: 5px;
}

.timeline-date-time {
  margin-bottom: 0px;
  color: #BCBCBC;
}

.react-datepicker-wrapper {
  width: 15% !important;
  margin-right: 10px;
}
.date-input {
  margin-left: 15px;
  background-color: #F6F7FB !important;
  border-color: #F6F7FB !important;
  color: #888888 !important;
  box-shadow: none !important;
}

.timeline-no-data {
  margin-left: 5px;
}

/* ---- Page Not Found ---- */
.page-not-found-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-not-found-body {
  height: 70%;
  width: 65%;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 10px 10px 64px 0 rgb(0 0 0 / 25%);
  -webkit-box-shadow: 10px 10px 64px 0 rgb(0 0 0 / 25%);
  -moz-box-shadow: 10px 10px 64px 0 rgba(0,0,0,.25);
  display: flex;
}

.page-not-found-image-container {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-not-found-image {
  width: 80%;
  height: auto;
}

.page-not-found-message-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 50%;
}

.page-not-found-title {
  font-family: "SukhumvitSet-Medium";
  color: #b31117;
  font-size: 72px;
  margin-bottom: 0px;
}

.page-not-found-sub-title {
  font-family: "SukhumvitSet-SemiBold";
  color: #000000;
  font-size: 22px;
}

.page-not-found-text {
  font-family: "SukhumvitSet-Light";
  color: rgba(153, 153, 153, 0.75);
  font-size: 20px;
}

.homepage-btn {
  background-color: #b31117 !important;
  border-color: #b31117 !important;
  font-family: "SukhumvitSet-Medium" !important;
}

/* ---- Report ---- */
.table-container {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  margin-top: 10px;
  border-radius: 4px;
  padding: 15px;
}

.table-container > h5 {
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  font-family: "SukhumvitSet-SemiBold";
}

.table-container > p {
  margin-left: 5px;
  margin-bottom: 15px;
  font-family: "SukhumvitSet-Medium";
  /* color: #BCBCBC; */
  color: #888888;
}